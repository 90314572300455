import angular from 'angular'
import axios from 'axios'

function PaymentSuccessController ($scope, $rootScope, $routeParams) {
  const vm = this

  vm.TRANSACTION_ID = $routeParams.transactionId
  vm.EMAIL = 'UNKNOWN'
  vm.TOTAL = 0.00

  const formData = new FormData()
  if ($routeParams.type === 'booth') {
    formData.append('binvoiceID', $routeParams.transactionId)
  } else {
    formData.append('tinvoiceID', $routeParams.transactionId)
  }

  if (localStorage.getItem('accessToken') !== null) {
    axios
      .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/UserRefresh.php` : `${process.env.LOCAL_APP_URL}/jSON/UserRefresh.php`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data

          const user = {
            id: data.id,
            name: data.name,
            email: data.email,
            credits: data.credits === null ? 0 : parseInt(data.credits)
          }

          localStorage.setItem('user', JSON.stringify(user))
          localStorage.setItem('accessToken', data.jwt)
        } else {
          $rootScope.USER = {
            id: '',
            name: '',
            email: '',
            credits: 0
          }

          localStorage.removeItem('sessionId')
          localStorage.removeItem('user')
          localStorage.removeItem('accessToken')

          $rootScope.SIGNED_IN = false

          $rootScope.toggleGenericModal('Authentication', 'Your session has expired. Please sign in again.')
        }
      })
      .catch(() => {
        $rootScope.USER = {
          id: '',
          name: '',
          email: '',
          credits: 0
        }

        localStorage.removeItem('sessionId')
        localStorage.removeItem('user')
        localStorage.removeItem('accessToken')

        $rootScope.SIGNED_IN = false

        $rootScope.toggleGenericModal('Authentication', 'Your session has expired. Please sign in again.')
      })
  }

  axios
    .post(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/event/thank-you.php` : `${process.env.LOCAL_APP_URL}/event/thank-you.php`, formData)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data

        if (response.status === 200) {
          vm.TOTAL = data.total
          vm.EMAIL = data.email

          $scope.$apply()
        }

        localStorage.removeItem('cart')
        localStorage.removeItem('cartId')
        $rootScope.clearCart()
      }
    })
    .catch(() => {
      $rootScope.toggleGenericModal('Payment Confirmation', 'We could not process your request. Please try again.')
    })
}

angular.module('app').controller('PaymentSuccessController', ['$scope', '$rootScope', '$routeParams', PaymentSuccessController])
