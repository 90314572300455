import angular from 'angular'
import axios from 'axios'

function AboutController ($scope) {
  const vm = this

  vm.CONTENT = {
    header: {
      headerImage: '',
      headerImageTitle: 'Body mind spirit',
      headerImageText: 'About our family'
    },
    container1: {
      title: '',
      content: '',
      container1Image1: '',
      container1Image2: ''
    },
    container2: {
      title: 'Awaken your sixth sense',
      container2Image: '',
      sections: []
    }
  }
  vm.PEOPLE = []

  axios.get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/AboutScreen.php` : `${process.env.LOCAL_APP_URL}/jSON/AboutScreen.php`).then(response => {
    if (response.status === 200) {
      const data = response.data

      vm.CONTENT.header = data.header
      vm.CONTENT.container1 = data.container1
      vm.CONTENT.container2 = data.container2

      for (let i = 0; i < data.people.length; i += 2) {
        const chunk = [{
          id: data.people[i].id,
          src: data.people[i].picture,
          name: data.people[i].name,
          position: data.people[i].title,
          description: data.people[i].description
        }]

        if (data.people[i + 1] !== undefined) {
          chunk.push({
            id: data.people[i + 1].id,
            src: data.people[i + 1].picture,
            name: data.people[i + 1].name,
            position: data.people[i + 1].title,
            description: data.people[i + 1].description
          })
        }

        vm.PEOPLE.push(chunk)
      }

      $scope.$apply()
    }
  })
}

angular.module('app').controller('AboutController', ['$scope', AboutController])
