import angular from 'angular'
import './index.scss'

function collapsible () {
  return {
    restrict: 'E',
    scope: {
      label: '@',
      content: '@'
    },
    transclude: false,
    templateUrl: `${window.PUBLIC_PATH}views/directives/collapsible/index.html`,
    controller: ['$scope', function ($scope) {
      $scope.open = false

      $scope.toggleOpen = function () {
        $scope.open = !$scope.open
      }
    }]
  }
}

angular.module('app').directive('collapsible', collapsible)
