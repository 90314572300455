import angular from 'angular'

angular.module('app').config(['$routeProvider', '$locationProvider', ($routeProvider, $locationProvider) => {
  $routeProvider
    .when('/', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/HomeScreen/index.html`,
      controller: 'HomeController',
      controllerAs: 'homeCtrl'
    })
    .when('/events', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/EventsScreen/index.html`,
      controller: 'EventsController',
      controllerAs: 'eventsCtrl'
    })
    .when('/events/:id', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/EventScreen/index.html`,
      controller: 'EventController',
      controllerAs: 'eventCtrl'
    })
    .when('/floor-plan/:id', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/FloorPlanScreen/index.html`,
      controller: 'FloorPlanController',
      controllerAs: 'floorPlanCtrl'
    })
    .when('/about', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/AboutScreen/index.html`,
      controller: 'AboutController',
      controllerAs: 'aboutCtrl'
    })
    .when('/careers', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/CareersScreen/index.html`,
      controller: 'CareersController',
      controllerAs: 'careersCtrl'
    })
    .when('/checkout', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/CheckoutScreen/index.html`,
      controller: 'CheckoutController',
      controllerAs: 'checkoutCtrl'
    })
    .when('/faq', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/FAQScreen/index.html`,
      controller: 'FAQController',
      controllerAs: 'faqCtrl'
    })
    .when('/exhibitor', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/ExhibitorScreen/index.html`,
      controller: 'ExhibitorController',
      controllerAs: 'exhibitorCtrl',
      css: `${window.PUBLIC_PATH}styles/ExhibitorAdditional.css`
    })
    .when('/exhibitor/join', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/ExhibitorJoinScreen/index.html`,
      controller: 'ExhibitorJoinController',
      controllerAs: 'exhibitorJoinCtrl'
    })
    .when('/performer', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/PerformerScreen/index.html`,
      controller: 'PerformerController',
      controllerAs: 'performerCtrl'
    })
    .when('/performer/join', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/PerformerJoinScreen/index.html`,
      controller: 'PerformerJoinController',
      controllerAs: 'performerJoinCtrl'
    })
    .when('/attendee', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/AttendeeScreen/index.html`,
      controller: 'AttendeeController',
      controllerAs: 'attendeeCtrl'
    })
    .when('/attendee/join', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/AttendeeJoinScreen/index.html`,
      controller: 'AttendeeJoinController',
      controllerAs: 'attendeeJoinCtrl'
    })
    .when('/sponsor', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/AttendeeScreen/index.html`,
      controller: 'AttendeeController',
      controllerAs: 'attendeeCtrl'
    })
    .when('/sponsor/join', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/AttendeeJoinScreen/index.html`,
      controller: 'AttendeeJoinController',
      controllerAs: 'attendeeJoinCtrl'
    })
    .when('/payment-success/:transactionId/:type', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/PaymentSuccessScreen/index.html`,
      controller: 'PaymentSuccessController',
      controllerAs: 'paymentSuccessCtrl'
    })
    .when('/privacy-policy', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/PrivacyPolicyScreen/index.html`,
      controller: 'PrivacyPolicyController',
      controllerAs: 'privacyPolicyCtrl'
    })
    .when('/terms-of-service', {
      templateUrl: `${window.PUBLIC_PATH}views/screens/TermsOfServiceScreen/index.html`,
      controller: 'TermsOfServiceController',
      controllerAs: 'termsOfServiceCtrl'
    })
    .otherwise({
      template: '404 Error'
    })

  // if (process.env.NODE_ENV === 'production') {
  $locationProvider.html5Mode(true)
  // }
}])
