/* eslint-disable angular/document-service */
import angular from 'angular'

function dropdown () {
  return {
    restrict: 'A',
    scope: {
      label: '@',
      value: '=',
      options: '=',
      onChange: '='
    },
    transclude: false,
    templateUrl: `${window.PUBLIC_PATH}views/directives/dropdown/index.html`,
    controller: ['$scope', function ($scope) {
      $scope.open = false

      $scope.query = ''
      $scope.filteredOptions = []

      $scope.toggleOpen = function () {
        $scope.open = !$scope.open
      }

      $scope._onChange = function (value) {
        if (document.activeElement) {
          document.activeElement.blur()
        }
        if (angular.isDefined($scope.onChange)) {
          $scope.onChange(value)
        }
      }

      $scope.$watch(function () {
        return $scope.query
      }, function (newValue) {
        if (newValue === '') {
          $scope.filteredOptions = $scope.options
        } else {
          $scope.filteredOptions = $scope.options.filter(element => element.label.toLowerCase().includes(newValue.toLowerCase()))
        }
      })

      $scope.$watch(function () {
        return $scope.options
      }, function (newValue) {
        $scope.filteredOptions = newValue
      })
    }],
    link: function (scope, element) {
      const findInput = element[0].querySelector('input')
      if (findInput) {
        findInput.addEventListener('focus', function () {
          scope.open = true
          scope.query = ''

          scope.$apply()
        })
        findInput.addEventListener('blur', function () {
          scope.open = false
          scope.query = ''
        })
        element[0].addEventListener('click', function (event) {
          if (event.target.tagName === 'BUTTON') {
            findInput.focus()
          }
        })
      }
    }
  }
}

angular.module('app').directive('dropdown', dropdown)
