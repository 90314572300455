import angular from 'angular'
import axios from 'axios'
import moment from 'moment'

function HomeController ($scope, $document, $interval) {
  const vm = this

  vm.PICTURES = []
  vm.activeIndex = 0

  let sliderInterval
  let spotlightSliderInterval
  const slider = angular.element($document[0].getElementById('slider'))[0]
  const mobileSlider = angular.element($document[0].getElementById('mobile-slider'))[0]
  const spotlightSlider = angular.element($document[0].getElementById('spotlight-slider'))[0]

  vm.SPOTLIGHT = {
    PICTURES: [],
    activeIndex: 0
  }

  vm.UPCOMING_EVENTS = []

  const startSlider = () => {
    sliderInterval = $interval(() => {
      vm.activeIndex++
      if (vm.activeIndex >= vm.PICTURES.length) {
        vm.activeIndex = 0
      }

      const sliderPictures = slider.children
      for (let i = 0; i < sliderPictures.length; i++) {
        slider.children.item(i).classList.remove('active')
        mobileSlider.children.item(i).classList.remove('active')

        if (i === vm.activeIndex) {
          slider.children.item(i).classList.add('active')
          mobileSlider.children.item(i).classList.add('active')
        }
      }
    }, 3000)
  }

  const stopSlider = () => {
    if (angular.isDefined(sliderInterval)) {
      $interval.cancel(sliderInterval)
      sliderInterval = undefined
    }
  }

  const startSpotlightSlider = () => {
    spotlightSliderInterval = $interval(() => {
      vm.SPOTLIGHT.activeIndex++
      if (vm.SPOTLIGHT.activeIndex >= vm.SPOTLIGHT.PICTURES.length) {
        vm.SPOTLIGHT.activeIndex = 0
      }

      const sliderPictures = spotlightSlider.children
      for (let i = 0; i < sliderPictures.length; i++) {
        spotlightSlider.children.item(i).classList.remove('active')

        if (i === vm.activeIndex) {
          spotlightSlider.children.item(i).classList.add('active')
        }
      }
    }, 3000)
  }

  const stopSpotlightSlider = () => {
    if (angular.isDefined(spotlightSliderInterval)) {
      $interval.cancel(spotlightSliderInterval)
      spotlightSliderInterval = undefined
    }
  }

  vm.startSlider = startSlider
  vm.stopSlider = stopSlider

  vm.startSpotlightSlider = startSpotlightSlider
  vm.stopSpotlightSlider = stopSpotlightSlider

  vm.changeSlide = function (index) {
    stopSlider()

    vm.activeIndex = index
  }

  vm.changeSpotlightSlide = function (index) {
    stopSpotlightSlider()

    vm.SPOTLIGHT.activeIndex = index

    startSpotlightSlider()
  }

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/HomeScreen.php` : `${process.env.LOCAL_APP_URL}/jSON/HomeScreen.php`)
    .then((response) => {
      const data = response.data

      if (response.status === 200) {
        vm.PICTURES = data.events.map((element, index) => ({
          id: element.id,
          src: element.picture,
          active: index === 0,
          city: element.city,
          startDate: element.startDate,
          endDate: element.endDate,
          parsedDate: `${moment.utc(element.startDate).format('MMM DD')}-${moment.utc(element.endDate).format('DD')}`,
          place: element.place,
          title: element.title,
          ticketUrl: element.buyTicketURL,
          boothUrl: element.buyBoothURL,
          eventUrl: '',
          socials: element.socials ? element.socials : []
        }))

        vm.SPOTLIGHT.PICTURES = data.spotlight.map((element, index) => ({
          id: element.id,
          src: element.picture,
          active: index === 0,
          title: element.title,
          city: element.city,
          startDate: element.startDate,
          endDate: element.endDate,
          parsedDate: `${moment.utc(element.startDate).format('MMM D')}-${moment.utc(element.endDate).format('DD')}`
        }))

        vm.UPCOMING_EVENTS = data.upcomingEvents.map((element, index) => ({
          id: element.id,
          title: element.title,
          place: element.place,
          address: element.address,
          startDate: element.startDate,
          endDate: element.endDate,
          parsedDate: `${moment.utc(element.startDate).format('MMM DD')}-${moment.utc(element.endDate).format('DD')}`
        }))

        $scope.$apply()
      }
    })
    .catch(e => console.error(e))

  startSlider()
  startSpotlightSlider()
}

angular.module('app').controller('HomeController', ['$scope', '$document', '$interval', HomeController])
