import angular from 'angular'
import axios from 'axios'

function FAQController ($scope) {
  const vm = this

  vm.query = ''

  vm.FILTERS = []

  vm.ATTENDEES = []
  vm.PERFORMERS = []
  vm.VENDORS = []

  vm.FILTERED_ATTENDEES = []
  vm.FILTERED_PERFORMERS = []
  vm.FILTERED_VENDORS = []

  vm.toggleFilter = function (value) {
    const findIndex = vm.FILTERS.findIndex(element => element === value)
    if (findIndex === -1) {
      vm.FILTERS.push(value)
    } else {
      vm.FILTERS.splice(findIndex, 1)
    }
  }

  $scope.$watch('faqCtrl.query', function (value) {
    const sanitizedValue = value.trim().toLowerCase()

    if (sanitizedValue === '') {
      vm.FILTERED_ATTENDEES = vm.ATTENDEES
      vm.FILTERED_PERFORMERS = vm.PERFORMERS
      vm.FILTERED_VENDORS = vm.VENDORS
    } else {
      vm.FILTERED_ATTENDEES = vm.ATTENDEES.filter(element => element.label.includes(sanitizedValue))
      vm.FILTERED_PERFORMERS = vm.PERFORMERS.filter(element => element.label.includes(sanitizedValue))
      vm.FILTERED_VENDORS = vm.VENDORS.filter(element => element.label.includes(sanitizedValue))
    }
  })

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/FAQScreen.php` : `${process.env.LOCAL_APP_URL}/jSON/FAQScreen.php`)
    .then(response => {
      if (response.status === 200) {
        const data = response.data

        vm.ATTENDEES = vm.FILTERED_ATTENDEES = data.attendees
        vm.PERFORMERS = vm.FILTERED_PERFORMERS = data.performers
        vm.VENDORS = vm.FILTERED_VENDORS = data.vendors

        $scope.$apply()
      }
    })
    .catch(e => console.error(e))
}

angular.module('app').controller('FAQController', ['$scope', FAQController])
