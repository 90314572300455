import angular from 'angular'
import axios from 'axios'

function PrivacyPolicyController ($scope) {
  const vm = this

  vm.CONTENT = {
    header: {
      headerImage: null,
      headerImageTitle: 'Body mind spirit',
      headerImageText: 'Privacy Policy'
    },
    content: ''
  }

  axios.get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/PrivacyPolicy.php` : `${process.env.LOCAL_APP_URL}/jSON/PrivacyPolicy.php`).then(response => {
    if (response.status === 200) {
      const data = response.data

      vm.CONTENT.header = data.header
      vm.CONTENT.content = data.content

      $scope.$apply()
    }
  })
}

angular.module('app').controller('PrivacyPolicyController', ['$scope', PrivacyPolicyController])
