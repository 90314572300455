import angular from 'angular'
import './index.scss'

function modal () {
  return {
    restrict: 'E',
    scope: {
      visible: '=',
      toggleOpen: '&',
      showExtraControls: '=',
      handlePrevious: '&',
      handleNext: '&'
    },
    transclude: true,
    templateUrl: `${window.PUBLIC_PATH}views/directives/modal/index.html`
  }
}

angular.module('app').directive('modal', modal)
