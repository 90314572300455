import angular from 'angular'
import axios from 'axios'

function PerformerController ($scope) {
  const vm = this

  vm.CONTENT = {
    head: {
      headImage: `${window.PUBLIC_PATH}images/il-performer-header.webp`,
      headImageTitle: 'Become a performer'
    },
    header: {
      header: '',
      headerImage: `${window.PUBLIC_PATH}images/il-performer-header-intro.webp`
    },
    container1: {
      title: '',
      content: ''
    },
    container2: {
      title: '',
      content: ''
    },
    quote: '',
    container3: {
      title: '',
      content: ''
    },
    container4: {
      title: '',
      content: ''
    },
    container5: {
      container5Image: `${window.PUBLIC_PATH}images/il-performer-footer.webp`,
      container5ImageTitle: 'Become a performer'
    }
  }

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/PerformerScreen.php` : `${process.env.LOCAL_APP_URL}/jSON/PerformerScreen.php`)
    .then(response => {
      if (response.status === 200) {
        const data = response.data

        vm.CONTENT.head = data.head
        vm.CONTENT.header = data.header
        vm.CONTENT.container1 = data.container1
        vm.CONTENT.container2 = data.container2
        vm.CONTENT.quote = data.quote
        vm.CONTENT.container3 = data.container3
        vm.CONTENT.container4 = data.container4
        vm.CONTENT.container5 = data.container5

        $scope.$apply()
      }
    })
    .catch(e => console.error(e))
}

angular.module('app').controller('PerformerController', ['$scope', PerformerController])
