/* eslint-disable angular/timeout-service */
/* eslint-disable angular/document-service */
import angular from 'angular'
import axios from 'axios'
import './index.scss'

function appBar () {
  return {
    restrict: 'E',
    scope: {
      customStyle: '='
    },
    controller: ['$scope', '$rootScope', '$location', 'vcRecaptchaService', function ($scope, $rootScope, $location, vcRecaptchaService) {
      const backgroundMusic = new Audio(`${window.PUBLIC_PATH}music/music-2.webm`)
      backgroundMusic.load()
      backgroundMusic.addEventListener('playing', (e) => {
        $scope.isPlaying = true
      })
      backgroundMusic.addEventListener('pause', (e) => {
        $scope.isPlaying = false
      })

      $rootScope.appLogo = `${window.PUBLIC_PATH}images/logo.svg`

      $scope.APP_BAR_CONTENT = {
        sponsors: [],
        socialMedia: []
      }

      $scope.widgetId = null
      $scope.reCaptcha = null

      $rootScope.scrollTo = null
      $rootScope.scrollOnPath = null

      $rootScope.navigateTo = (url, scrollTo = '.scroll-anchor') => {
        $rootScope.scrollTo = scrollTo
        $rootScope.scrollOnPath = url.replace('/#!', '')
        $location.path(url)
        onScrollListener()
      }

      $rootScope.USER = {
        id: '',
        name: '',
        email: '',
        credits: 0.00
      }

      $rootScope.SIGNED_IN = false

      $scope.GENERIC_MODAL = {
        visible: false,
        title: '',
        message: '',
        buttonText: 'Got It',
        callback: null
      }

      $scope.SIGN_IN_FORM = {
        email: '',
        password: ''
      }

      $scope.FORGOT_PASSWORD_FORM = {
        email: ''
      }

      $scope.CONTACT_FORM = {
        name: '',
        email: '',
        subject: '',
        company: '',
        message: ''
      }

      $scope.signInModalVisible = false
      $scope.forgorPasswordModalVisible = false
      $scope.contactUsModalVisible = false
      $scope.isMenuOpened = false

      $scope.cartVisible = false

      const toggleGenericModal = function (title = '', message = '', callback = null, buttonText = 'Got It') {
        if ($scope.GENERIC_MODAL.visible && $scope.GENERIC_MODAL.callback !== null) {
          $scope.GENERIC_MODAL.callback()
        }

        $scope.signInModalVisible = false
        $scope.forgotPasswordModalVisible = false
        $scope.contactUsModalVisible = false

        $scope.GENERIC_MODAL.title = title
        $scope.GENERIC_MODAL.message = message
        $scope.GENERIC_MODAL.buttonText = buttonText
        $scope.GENERIC_MODAL.callback = callback
        $scope.GENERIC_MODAL.visible = !$scope.GENERIC_MODAL.visible
      }

      $scope.toggleGenericModal = toggleGenericModal
      $rootScope.toggleGenericModal = toggleGenericModal

      $scope.openUrl = function (url) {
        window.open(url, '_blank')
      }

      $scope.getPlatformIcon = function (name) {
        if (name.toLowerCase().includes('facebook')) {
          return `${window.PUBLIC_PATH}images/icons/ic-facebook.svg`
        } else if (name.toLowerCase().includes('twitter')) {
          return `${window.PUBLIC_PATH}images/icons/ic-twitter.svg`
        } else if (name.toLowerCase().includes('instagram')) {
          return `${window.PUBLIC_PATH}images/icons/ic-instagram.svg`
        } else if (name.toLowerCase().includes('pinterest')) {
          return `${window.PUBLIC_PATH}images/icons/ic-pinterest.svg`
        }
      }

      $scope.setWidgetId = function (widgetId) {
        $scope.widgetId = widgetId
      }

      $scope.getCartItemsCount = function () {
        if (!$rootScope.getCartItems) {
          return 0
        }

        return $rootScope.getCartItems().length
      }

      $scope.toggleSignInModal = function () {
        $scope.signInModalVisible = !$scope.signInModalVisible
      }
      $rootScope.toggleSignInModal = $scope.toggleSignInModal

      $scope.toggleForgotPasswordModal = function () {
        $scope.forgotPasswordModalVisible = !$scope.forgotPasswordModalVisible
      }

      $scope.toggleContactUsModal = function () {
        $scope.contactUsModalVisible = !$scope.contactUsModalVisible
      }

      $scope.signIn = function () {
        if (!$scope.SIGN_IN_FORM.email || !$scope.SIGN_IN_FORM.password) {
          alert('Please fill all required details.')
          return
        }

        const formData = new FormData()
        formData.append('email', $scope.SIGN_IN_FORM.email)
        formData.append('password', $scope.SIGN_IN_FORM.password)

        axios
          .post(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/UserLogin.php` : `${process.env.LOCAL_APP_URL}/jSON/UserLogin.php`, formData)
          .then((response) => {
            if (response.status === 200) {
              const data = response.data

              if (data.status === 0) {
                $rootScope.toggleGenericModal('Sign In', data.message)
                return
              }

              const user = {
                id: data.id,
                name: data.name,
                email: data.email,
                credits: data.credits === null ? 0 : parseInt(data.credits)
              }

              localStorage.setItem('sessionId', data.session_id)
              localStorage.setItem('user', JSON.stringify(user))
              localStorage.setItem('accessToken', data.jwt)

              $scope.signInModalVisible = false
              $rootScope.SIGNED_IN = true

              $scope.$apply()
            }
          }).catch((e) => {
            if (e.response && e.response.status === 400 && e.response.data && e.response.data.message) {
              $rootScope.toggleGenericModal('Sign In', e.response.data.message)
              $scope.$apply()
            }
          })
      }

      $scope.forgotPassword = function () {
        if (!$scope.FORGOT_PASSWORD_FORM.email) {
          alert('Please fill all required details.')
          return
        }

        const formData = new FormData()
        formData.append('email', $scope.FORGOT_PASSWORD_FORM.email)

        axios
          .post(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/ForgotPassword.php` : `${process.env.LOCAL_APP_URL}/jSON/ForgotPassword.php`, formData)
          .then((response) => {
            if (response.status === 200) {
              const data = response.data

              if (data.status === 0) {
                $rootScope.toggleGenericModal('Forgot Password', data.message)
                return
              }

              $scope.forgotPasswordModalVisible = false
              $scope.signInModalVisible = true
            }
          }).catch((e) => {
            $rootScope.toggleGenericModal('Forgot Password', e.message)
          })
      }

      $scope.exhibitorPortal = function () {
        window.location = `${(process.env.NODE_ENV === 'production' ? process.env.APP_URL : process.env.LOCAL_APP_URL)}/exhibitoradmin/login.php?PHPSESSID=${localStorage.getItem('sessionId')}`
      }

      $scope.signOut = function () {
        axios
          .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/UserLogout.php` : `${process.env.LOCAL_APP_URL}/jSON/UserLogout.php`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
          })
          .then((response) => {
            if (response.status === 200) {
              $rootScope.USER = {
                id: '',
                name: '',
                email: '',
                credits: 0
              }

              localStorage.removeItem('sessionId')
              localStorage.removeItem('user')
              localStorage.removeItem('accessToken')

              $rootScope.SIGNED_IN = false

              $scope.$apply()
            }
          }).catch((e) => {
            $rootScope.toggleGenericModal('Sign Out', e.message)
          })
      }

      const toggleCart = () => {
        $scope.cartVisible = !$scope.cartVisible
      }

      $rootScope.toggleCart = toggleCart
      $scope.toggleCart = toggleCart

      $scope.toggleMenu = function () {
        $scope.isMenuOpened = !$scope.isMenuOpened
      }

      $scope.toggleMusic = function () {
        if (angular.isDefined(backgroundMusic)) {
          backgroundMusic.playsinline = true

          if (backgroundMusic.paused) {
            $scope.isPlaying = true
            backgroundMusic.play()
          } else {
            $scope.isPlaying = false
            backgroundMusic.pause()
          }
        }
      }

      $scope.sendContactForm = function () {
        if (vcRecaptchaService.getResponse($scope.widgetId) === '') {
          toggleGenericModal('Contact us', 'Please check the reCaptcha box.')
          return
        }

        const formData = new FormData()
        formData.append('name', $scope.CONTACT_FORM.name)
        formData.append('email', $scope.CONTACT_FORM.email)
        formData.append('subject', $scope.CONTACT_FORM.subject)
        formData.append('companyname', $scope.CONTACT_FORM.company)
        formData.append('message', $scope.CONTACT_FORM.message)

        axios
          .post(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/ContactForm.php` : `${process.env.LOCAL_APP_URL}/jSON/ContactForm.php`, formData)
          .then(() => {
            $scope.CONTACT_FORM = {
              name: '',
              email: '',
              subject: '',
              company: '',
              message: ''
            }

            $rootScope.toggleGenericModal('Contact Us', 'Thank you for contacting us!')
          })
          .catch(() => {
            $rootScope.toggleGenericModal('Contact Us', 'We could not send your request. Please try again.')
          })
      }

      // const checkUser = localStorage.getItem('user')
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        axios
          .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/UserValidate.php` : `${process.env.LOCAL_APP_URL}/jSON/UserValidate.php`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
          })
          .then((response) => {
            if (response.status === 200) {
              const data = response.data

              if (data.status === 0) {
                $rootScope.USER = {
                  id: '',
                  name: '',
                  email: '',
                  credits: 0
                }

                localStorage.removeItem('sessionId')
                localStorage.removeItem('user')
                localStorage.removeItem('accessToken')

                $rootScope.SIGNED_IN = false

                $rootScope.toggleGenericModal('Authentication', 'Your session has expired. Please sign in again.')
                return
              }

              const user = {
                id: data.user_data.data.id,
                name: data.user_data.data.name,
                email: data.user_data.data.email,
                credits: data.user_data.data.credits === null ? 0 : parseInt(data.user_data.data.credits)
              }

              localStorage.setItem('sessionId', data.session_id)
              localStorage.setItem('user', JSON.stringify(user))

              $scope.signInModalVisible = false
              $rootScope.SIGNED_IN = true
            } else if (response.status === 400) {
              $rootScope.USER = {
                id: '',
                name: '',
                email: '',
                credits: 0
              }

              localStorage.removeItem('sessionId')
              localStorage.removeItem('user')
              localStorage.removeItem('accessToken')

              $rootScope.SIGNED_IN = false

              $rootScope.toggleGenericModal('Authentication', 'Your session has expired. Please sign in again.')
            }
          }).catch((e) => {
            if (e.response) {
              if (e.response.status === 400) {
                $rootScope.USER = {
                  id: '',
                  name: '',
                  email: '',
                  credits: 0
                }

                localStorage.removeItem('sessionId')
                localStorage.removeItem('user')
                localStorage.removeItem('accessToken')

                $rootScope.SIGNED_IN = false

                $rootScope.toggleGenericModal('Authentication', 'Your session has expired. Please sign in again.')
              }
            }
            console.error(e)
          })
      }

      function onScrollListener () {
        const findElement = document.querySelector($rootScope.scrollTo)
        if (findElement) {
          const bodyRect = document.body.getBoundingClientRect()
          const elemRect = findElement.getBoundingClientRect()
          const offset = elemRect.top - bodyRect.top

          let top = offset - 200
          if (top < 0) {
            top = 0
          }

          window.scrollTo({ left: 0, top, behavior: 'smooth' })
          $rootScope.scrollTo = null
          $rootScope.scrollOnPath = null
          window.removeEventListener('scroll', onScrollListener)
        }
      }

      $scope.$watchGroup(
        [
          function () {
            return $location.path()
          },
          function () {
            return $rootScope.scrollTo
          }
        ], function () {
          setTimeout(() => {
            if ($location.path() === $rootScope.scrollOnPath && $rootScope.scrollTo !== null) {
              window.addEventListener('scroll', onScrollListener)

              const findElement = document.querySelector($rootScope.scrollTo)
              if (findElement) {
                findElement.scrollIntoView({ behavior: 'smooth' })
              }
            }
          }, 500)
        })

      axios
        .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/FooterDirective.php` : `${process.env.LOCAL_APP_URL}/jSON/FooterDirective.php`)
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            $scope.APP_BAR_CONTENT = data

            $scope.$apply()
          }
        })
        .catch(e => alert(e))
    }],
    templateUrl: `${window.PUBLIC_PATH}views/directives/appBar/index.html`
  }
}

angular.module('app').directive('appBar', appBar)
