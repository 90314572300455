import angular from 'angular'
import axios from 'axios'
import './index.scss'

function appFooter () {
  return {
    restrict: 'E',
    scope: {
      hideJoinUs: '=',
      includeMargin: '='
    },
    controller: ['$scope', '$rootScope', 'vcRecaptchaService', function ($scope, $rootScope, vcRecaptchaService) {
      $scope.JOIN_US_CONTENT = {
        title: '',
        content: ''
      }

      $scope.widgetId = null
      $scope.reCaptcha = null

      $scope.SUBSCRIBE_FORM = {
        email: '',
        city: ''
      }

      $scope.CITIES = {
        value: null,
        options: []
      }

      $scope.subscribeModalVisible = false

      $scope.FOOTER_CONTENT = {
        sponsors: [],
        socialMedia: [],
        bmsePhone: '',
        bmseEmail: ''
      }

      $scope.setWidgetId = function (widgetId) {
        $scope.widgetId = widgetId
      }

      $scope.changeCity = function (value) {
        $scope.CITIES.value = value
      }

      $scope.openUrl = function (url) {
        window.open(url, '_blank')
      }

      $scope.getPlatformIcon = function (name) {
        if (name.toLowerCase().includes('facebook')) {
          return `${window.PUBLIC_PATH}images/icons/ic-facebook.svg`
        } else if (name.toLowerCase().includes('twitter')) {
          return `${window.PUBLIC_PATH}images/icons/ic-twitter.svg`
        } else if (name.toLowerCase().includes('instagram')) {
          return `${window.PUBLIC_PATH}images/icons/ic-instagram.svg`
        } else if (name.toLowerCase().includes('pinterest')) {
          return `${window.PUBLIC_PATH}images/icons/ic-pinterest.svg`
        }
      }

      $scope.toggleSubscribeModal = function () {
        $scope.subscribeModalVisible = !$scope.subscribeModalVisible
      }

      $scope.subscribe = function () {
        if (vcRecaptchaService.getResponse($scope.widgetId) === '') {
          $scope.toggleSubscribeModal()
          $rootScope.toggleGenericModal('Subscribe', 'Please check the reCaptcha boxx.')
          return
        }

        if ($scope.CITIES.value === null) {
          $scope.toggleSubscribeModal()
          $rootScope.toggleGenericModal('Subscribe', 'Please select city.')
          return
        }

        $scope.SUBSCRIBE_FORM.city = $scope.CITIES.value.value

        axios
          .post(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/StayConnected.php` : `${process.env.LOCAL_APP_URL}/jSON/StayConnected.php`, {
            email: $scope.SUBSCRIBE_FORM.email,
            city: $scope.SUBSCRIBE_FORM.city
          })
          .then(() => {
            $scope.SUBSCRIBE_FORM = {
              email: '',
              city: ''
            }
            $scope.widgetId = null
            $scope.reCaptcha = null

            $scope.toggleSubscribeModal()
            $rootScope.toggleGenericModal('Subscribe', 'Thank you for subscribing!')
          })
          .catch(() => {
            $scope.toggleSubscribeModal()
            $rootScope.toggleGenericModal('Subscribe', 'We could not send your request. Please try again.')
          })
      }

      axios
        .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/JoinUsDirective.php` : `${process.env.LOCAL_APP_URL}/jSON/JoinUsDirective.php`)
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            $scope.JOIN_US_CONTENT = data

            $scope.$apply()
          }
        })
        .catch(e => alert(e))

      axios
        .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/FooterDirective.php` : `${process.env.LOCAL_APP_URL}/jSON/FooterDirective.php`)
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            $scope.FOOTER_CONTENT = data
            $rootScope.appLogo = data.logo
            $rootScope.cartType = data.cartType ?? 'new'
            $rootScope.buyBoothURL = '/event/expo_booths_view_details.php?eid={expoid}&boothid={boothid}'
            $rootScope.buyTicketURL = '/event/vendor_tickets.php?eid={expoid}'

            $scope.$apply()
          }
        })
        .catch(e => alert(e))

      axios
        .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/getZonesCities.php` : `${process.env.LOCAL_APP_URL}/jSON/getZonesCities.php`)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data

            $scope.CITIES.options = data.allCities.map(element => ({
              label: element.name,
              value: element.citiId
            }))

            $scope.$apply()
          }
        })
        .catch(e => alert(e))
    }],
    templateUrl: `${window.PUBLIC_PATH}views/directives/appFooter/index.html`
  }
}

angular.module('app').directive('appFooter', appFooter)
