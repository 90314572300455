import angular from 'angular'
import './index.scss'

function loading () {
  return {
    restrict: 'E',
    scope: {
      primary: '=',
      loading: '=',
      content: '@'
    },
    transclude: false,
    templateUrl: `${window.PUBLIC_PATH}views/directives/loading/index.html`
  }
}

angular.module('app').directive('loading', loading)
