/* eslint-disable angular/no-run-logic */
import angular from 'angular'
import 'angular-route'
import 'angular-sanitize'
import 'angular-recaptcha'
import './route-styles.js'

angular.module('app', ['ngRoute', 'ngSanitize', 'routeStyles', 'vcRecaptcha']).config(['vcRecaptchaServiceProvider', function (vcRecaptchaServiceProvider) {
  vcRecaptchaServiceProvider.setSiteKey(process.env.GOOGLE_RECAPTCHA_TOKEN)
}])
