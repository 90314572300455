import angular from 'angular'
import axios from 'axios'

function ExhibitorController ($scope, $document, $interval) {
  const vm = this

  vm.PICTURES = []
  vm.activeIndex = 0

  vm.CONTENT = {
    testimonial: {
      testimonialTitle: '',
      testimonialText: ''
    },
    container1: {
      video: '',
      title: '',
      content: ''
    },
    container2: {
      title: '',
      list1: [],
      list2: [],
      list3: [],
      container2Image: `${window.PUBLIC_PATH}images/il-exhibitor-header-separator.webp`
    },
    container3: {
      container3Image: `${window.PUBLIC_PATH}images/il-exhibitor-header-separator-2.webp`,
      container3ImageTitle: 'Become an exhibitor'
    }
  }

  vm.GUIDES = []

  let sliderInterval
  const slider = angular.element($document[0].getElementById('slider'))[0]

  const startSlider = () => {
    sliderInterval = $interval(() => {
      vm.activeIndex++
      if (vm.activeIndex >= vm.PICTURES.length) {
        vm.activeIndex = 0
      }

      const sliderPictures = slider.children
      for (let i = 0; i < sliderPictures.length; i++) {
        slider.children.item(i).classList.remove('active')

        if (i === vm.activeIndex) {
          slider.children.item(i).classList.add('active')
        }
      }
    }, 3000)
  }

  const stopSlider = () => {
    if (angular.isDefined(sliderInterval)) {
      $interval.cancel(sliderInterval)
      sliderInterval = undefined
    }
  }

  vm.startSlider = startSlider
  vm.stopSlider = stopSlider

  vm.changeSlide = function (index) {
    stopSlider()

    vm.activeIndex = index
  }

  startSlider()

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/ExhibitorScreen.php` : `${process.env.LOCAL_APP_URL}/jSON/ExhibitorScreen.php`)
    .then(response => {
      if (response.status === 200) {
        const data = response.data

        vm.CONTENT.testimonial = data.testimonial
        vm.CONTENT.container1 = data.container1
        vm.CONTENT.container2 = data.container2
        vm.CONTENT.container3 = data.container3

        for (const guide of data.guides) {
          vm.GUIDES.push({
            title: guide.title,
            fileUrl: guide.url
          })
        }

        vm.PICTURES = data.slideshow.map((element, index) => ({
          title: element.title,
          src: element.picture,
          active: index === 0
        }))

        $scope.$apply()
      }
    })
    .catch(e => alert(e))
}

angular.module('app').controller('ExhibitorController', ['$scope', '$document', '$interval', ExhibitorController])
