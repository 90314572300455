import angular from 'angular'
import './index.scss'

function cart () {
  return {
    restrict: 'E',
    scope: {
      visible: '=',
      toggleOpen: '&'
    },
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
      $scope.ITEMS = []

      const increment = (index) => {
        $scope.ITEMS[index].quantity++

        localStorage.removeItem('cartId')
        localStorage.setItem('cart', JSON.stringify($scope.ITEMS))
      }

      const decrement = (index) => {
        $scope.ITEMS[index].quantity--

        if ($scope.ITEMS[index].quantity <= 0) {
          $scope.ITEMS.splice(index, 1)
        }

        localStorage.removeItem('cartId')
        localStorage.setItem('cart', JSON.stringify($scope.ITEMS))
      }

      const remove = (index) => {
        $scope.ITEMS.splice(index, 1)

        localStorage.removeItem('cartId')
        localStorage.setItem('cart', JSON.stringify($scope.ITEMS))
      }

      $scope.productAddedModalVisible = false

      $scope.toggleProductAddedModal = () => {
        if ($scope.productAddedModalVisible) {
          $scope.productAddedModalVisible = false
        } else {
          $scope.productAddedModalVisible = true
        }
      }

      $scope.toggleCart = () => {}
      $scope.increment = increment
      $scope.decrement = decrement
      $scope.remove = remove

      $rootScope.clearCart = () => {
        $scope.ITEMS = []
      }

      $rootScope.increment = increment
      $rootScope.decrement = decrement
      $rootScope.remove = remove
      $rootScope.getCartItems = () => $scope.ITEMS

      $rootScope.$watch(() => $rootScope.toggleCart, (newValue, oldValue) => {
        if (newValue) {
          $scope.toggleCart = $rootScope.toggleCart
        }
      })

      $rootScope.addItemToCart = function (item) {
        const findIndex = $scope.ITEMS.findIndex(element => element.itemId === item.id)
        if (findIndex !== -1) {
          $scope.ITEMS[findIndex].quantity++
        } else {
          $scope.ITEMS.push({
            itemId: item.id,
            slotId: item.slotId ? item.slotId : null,
            itemType: item.type,
            picture: item.picture && item.picture !== '' ? item.picture : null,
            name: item.name,
            quantity: item.quantity,
            price: item.price,
            extras: item.extras ? item.extras : []
          })
        }

        localStorage.setItem('cart', JSON.stringify($scope.ITEMS))

        $scope.productAddedModalVisible = true
      }

      const initialize = () => {
        const items = localStorage.getItem('cart')
        if (items !== null) {
          $scope.ITEMS = JSON.parse(items)
        }
      }

      initialize()
    }],
    templateUrl: `${window.PUBLIC_PATH}views/directives/cart/index.html`
  }
}

angular.module('app').directive('cart', cart)
