import angular from 'angular'
import axios from 'axios'
import moment from 'moment'

function EventsController ($scope, $document, $interval, $rootScope) {
  const vm = this

  vm.FILTERS = {
    REGION: {
      value: null,
      options: []
    },
    CITY: {
      value: null,
      options: []
    },
    SORT_BY: {
      value: {
        label: 'Start Date ↑',
        value: 1
      },
      options: [{
        label: 'Start Date ↑',
        value: 1
      }, {
        label: 'Start Date ↓',
        value: 2
      }, {
        label: 'End Date ↑',
        value: 3
      }, {
        label: 'End Date ↓',
        value: 4
      }]
    }
  }
  vm.ALL_EVENTS = []

  let sliderInterval

  const eventsSlider = angular.element($document[0].getElementById('events-slider'))[0]

  // Properties
  vm.viewAll = true

  vm.SLIDER = {
    PICTURES: [],
    activeIndex: 0
  }

  vm.PAGINATION = {}

  // Methods
  vm.clickEvent = function (event) {
    if (window.matchMedia('screen and (max-width: 991px)').matches) {
      $rootScope.navigateTo('/events/' + event.id)
    }
  }

  vm.startSlider = function () {
    sliderInterval = $interval(() => {
      vm.SLIDER.activeIndex++
      if (vm.SLIDER.activeIndex >= vm.SLIDER.PICTURES.length) {
        vm.SLIDER.activeIndex = 0
      }

      const sliderPictures = eventsSlider.children
      for (let i = 0; i < sliderPictures.length; i++) {
        eventsSlider.children.item(i).classList.remove('active')

        if (i === vm.SLIDER.activeIndex) {
          eventsSlider.children.item(i).classList.add('active')
        }
      }
    }, 3000)
  }

  vm.stopSlider = function () {
    if (angular.isDefined(sliderInterval)) {
      $interval.cancel(sliderInterval)
      sliderInterval = undefined
    }
  }

  vm.changeSlide = function (index) {
    vm.stopSlider()

    vm.SLIDER.activeIndex = index
  }

  vm.toggleViewAll = function () {
    if (vm.viewAll) {
      vm.viewAll = false
    } else {
      vm.viewAll = true
    }
  }

  vm.changePage = function (name, page) {
    vm.PAGINATION[name].currentPage = page
  }

  const preparePagination = (data, name) => {
    if (!angular.isDefined(vm.PAGINATION[name])) {
      vm.PAGINATION[name] = {}
    }

    vm.PAGINATION[name].list = data
    vm.PAGINATION[name].limit = 5

    // @screen-lg
    if (window.matchMedia('screen and (min-width: 992px)').matches) {
      vm.PAGINATION[name].limit = 3
    }

    // @screen-xl
    if (window.matchMedia('screen and (min-width: 1200px)').matches) {
      vm.PAGINATION[name].limit = 4
    }

    // @screen-xxl
    if (window.matchMedia('screen and (min-width: 1400px)').matches) {
      vm.PAGINATION[name].limit = 5
    }

    vm.PAGINATION[name].currentPage = 0
    vm.PAGINATION[name].totalPages = Math.ceil(vm.PAGINATION[name].list.length / vm.PAGINATION[name].limit)
    vm.PAGINATION[name].paginationHelper = []
    for (let i = 0; i < vm.PAGINATION[name].totalPages; i++) {
      vm.PAGINATION[name].paginationHelper.push(i)
    }
    vm.PAGINATION[name].chunks = []

    const list = []
    for (let i = 0; i < vm.PAGINATION[name].list.length; i += vm.PAGINATION[name].limit) {
      let limit = i + vm.PAGINATION[name].limit
      if (vm.PAGINATION[name].list.length < limit) {
        limit = vm.PAGINATION[name].list.length
      }

      const toPush = vm.PAGINATION[name].list.slice(i, limit)
      while (toPush.length < vm.PAGINATION[name].limit) {
        toPush.push(null)
      }

      list.push(toPush)
    }

    vm.PAGINATION[name].chunks = list
  }

  const applyFilters = () => {
    let filteredEvents = [...vm.ALL_EVENTS]
    if (vm.FILTERS.REGION.value && vm.FILTERS.REGION.value.value) {
      filteredEvents = filteredEvents.filter(event => event.regionId === vm.FILTERS.REGION.value.value)
    }
    if (vm.FILTERS.CITY.value && vm.FILTERS.CITY.value.value) {
      filteredEvents = filteredEvents.filter(event => event.cityId === vm.FILTERS.CITY.value.value)
    }
    if (vm.FILTERS.SORT_BY.value && vm.FILTERS.SORT_BY.value.value && vm.FILTERS.SORT_BY.value.value === 1) {
      filteredEvents = filteredEvents.sort((a, b) => a.startDateUnix - b.startDateUnix)
    }
    if (vm.FILTERS.SORT_BY.value && vm.FILTERS.SORT_BY.value.value && vm.FILTERS.SORT_BY.value.value === 2) {
      filteredEvents = filteredEvents.sort((a, b) => b.startDateUnix - a.startDateUnix)
    }
    if (vm.FILTERS.SORT_BY.value && vm.FILTERS.SORT_BY.value.value && vm.FILTERS.SORT_BY.value.value === 3) {
      filteredEvents = filteredEvents.sort((a, b) => a.endDateUnix - b.endDateUnix)
    }
    if (vm.FILTERS.SORT_BY.value && vm.FILTERS.SORT_BY.value.value && vm.FILTERS.SORT_BY.value.value === 4) {
      filteredEvents = filteredEvents.sort((a, b) => b.endDateUnix - a.endDateUnix)
    }

    preparePagination(filteredEvents, 'EVENTS')
  }

  vm.changeRegionFilter = function (value) {
    vm.FILTERS.REGION.value = value
  }

  vm.changeCityFilter = function (value) {
    vm.FILTERS.CITY.value = value
  }

  vm.changeSortByFilter = function (value) {
    vm.FILTERS.SORT_BY.value = value
  }

  vm.startSlider()

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/EventsScreen.php` : `${process.env.LOCAL_APP_URL}/jSON/EventsScreen.php`)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data

        vm.SLIDER.PICTURES = data.upcomingEvents.map((element, index) => ({
          id: element.id,
          src: element.picture,
          startDate: element.startDate,
          endDate: element.endDate,
          parsedDate: `${moment.utc(element.startDate).format('MMM D')} - ${moment.utc(element.endDate).format('D')}`,
          parsedTime: `${moment.utc(element.startDate).format('hh:mm A')} - ${moment.utc(element.endDate).format('hh:mm A')}`,
          saturdayParsedTime: `${moment.utc(`1970-01-01 ${element.SaturdayExpoStartTime}`).format('h')} - ${moment.utc(`1970-01-01 ${element.SaturdayExpoEndTime}`).format('h')}`,
          sundayParsedTime: `${moment.utc(`1970-01-01 ${element.SundayExpoStartTime}`).format('h')} - ${moment.utc(`1970-01-01 ${element.SundayExpoEndTime}`).format('h')}`,
          eventTimings: element.eventTimings,
          title: element.title,
          address: element.address,
          place: element.place,
          link: '',
          purchaseType: element.purchaseType
        }))

        const futureEvents = []
        for (const event of data.allevents) {
          const newEvent = {
            id: event.id,
            regionId: event.regionId,
            cityId: event.cityId,
            title: event.title,
            city: event.city,
            place: event.place,
            startDate: event.startDate,
            endDate: event.endDate,
            startDateUnix: moment.utc(event.startDate).valueOf(),
            endDateUnix: moment.utc(event.endDate).valueOf(),
            parsedDate: `${moment.utc(event.startDate).format('MMM D')} - ${moment.utc(event.endDate).format('D')}`,
            saturdayParsedTime: `${moment.utc(`1970-01-01 ${event.SaturdayExpoStartTime}`).format('h')} - ${moment.utc(`1970-01-01 ${event.SaturdayExpoEndTime}`).format('h')}`,
            sundayParsedTime: `${moment.utc(`1970-01-01 ${event.SundayExpoStartTime}`).format('h')} - ${moment.utc(`1970-01-01 ${event.SundayExpoEndTime}`).format('h')}`,
            eventTimings: event.eventTimings,
            src: event.picture,
            link: `/events/${event.id}`,
            purchaseType: event.purchaseType
          }

          vm.ALL_EVENTS.push(newEvent)
          futureEvents.push(newEvent)
        }

        preparePagination(futureEvents, 'EVENTS')

        $scope.$apply()
      }
    })
    .catch(e => alert(e))

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/getZonesCities.php` : `${process.env.LOCAL_APP_URL}/jSON/getZonesCities.php`)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data

        vm.FILTERS.REGION.options = data.zonesAndCities.map(element => ({
          label: element.zonename,
          value: element.zoneId
        }))
        vm.FILTERS.REGION.options.unshift({
          label: 'ALL',
          value: null
        })

        vm.FILTERS.CITY.options = data.allCities.map(element => ({
          label: element.name,
          value: element.citiId
        }))
        vm.FILTERS.CITY.options.unshift({
          label: 'ALL',
          value: null
        })

        $scope.$apply()
      }
    })
    .catch(e => alert(e))

  $scope.$watch(function () {
    return vm.FILTERS.REGION.value
  }, function (newValue) {
    if (newValue) {
      applyFilters()
    }
  })

  $scope.$watch(function () {
    return vm.FILTERS.CITY.value
  }, function (newValue) {
    if (newValue) {
      applyFilters()
    }
  })

  $scope.$watch(function () {
    return vm.FILTERS.SORT_BY.value
  }, function (newValue) {
    if (newValue) {
      applyFilters()
    }
  })

  window.scrollTo(0, 0)
}

angular.module('app').controller('EventsController', ['$scope', '$document', '$interval', '$rootScope', EventsController])
