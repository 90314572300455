/* eslint-disable angular/document-service */
/* eslint-disable angular/controller-as-vm */
import angular from 'angular'
import axios from 'axios'
import moment from 'moment'

const fabric = window.fabric

function FloorPlanController ($scope, $rootScope, $document, $routeParams) {
  const LOADING_PROGRESS = {
    images: false,
    mapData: null
  }

  let canvas
  const elementIds = []
  const canvasRefs = []

  let isInitialized = false

  // Construction
  let stoneFloor
  let brickFloor
  let grassFloor
  let wallBrickH
  let wallBrickV
  let doorH
  let doorV

  // Accessories
  let stage
  let couch
  let flowerpot
  let pillar
  let frontDesk
  let table
  let smallTable
  let chair
  let toilet
  let sink

  // Booths
  let booth10x10
  let booth10x7
  let booth6x10
  let booth6x6
  let booth6x8
  let booth7x10
  let booth8x10
  let booth8x12
  let booth8x8
  let booth9x9

  const vm = this

  vm.cartType = 'new'
  vm.isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf('Mobi') > -1
  vm.selectedBoothId = 0
  vm.lockingBooth = false
  vm.boothLocked = false
  vm.purchaseModalVisible = false
  vm.exhibitorModalVisible = false
  vm.exhibitorModalId = 0

  vm.query = ''
  vm.addToCartBtnLocked = true
  vm.EVENT_ID = $routeParams.id
  vm.TOTAL = 0.00
  vm.ORDER = []
  vm.INFO = {
    name: 'Unknown',
    importantNotes: '',
    startDate: '1970-01-01T00:00:00.000Z',
    endDate: '1970-01-01T00:00:00.000Z',
    formattedDate: 'January 1, 1970',
    picture: `${window.PUBLIC_PATH}images/il-floor-plan-header.webp`
  }
  vm.ELEMENTS = []
  vm.ALL_EXHIBITORS = []
  vm.EXHIBITORS = []
  vm.FORMS = []
  vm.EXTRAS = []
  vm.SOLD_BOOTHS = []
  vm.EXPO_SLOTS = {
    value: null,
    options: []
  }

  vm.SKIP_LECTURE = false

  vm.PROGRAM_GUIDE_URL = ''

  vm.purchaseBooth = function () {
    let url = $rootScope.buyBoothURL
    url = url.replace('{expoid}', vm.EVENT_ID)
    url = url.replace('{boothid}', vm.selectedBoothId)

    window.location = `${(process.env.NODE_ENV === 'production' ? process.env.APP_URL : process.env.LOCAL_APP_URL)}${url}`
  }

  vm.toggleExhibitorModal = function (id) {
    if (vm.exhibitorModalVisible) {
      vm.exhibitorModalVisible = false
      canvas.discardActiveObject()
    } else {
      vm.exhibitorModalVisible = true
      vm.exhibitorModalId = id
    }
  }

  vm.getExhibitor = function (id) {
    return vm.ALL_EXHIBITORS.find(element => element.id === id)
  }

  const fillFromDB = (type, props) => {
    if (type === 'wall-h') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: wallBrickH,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        stroke: '#414142',
        strokeWidth: 2,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        tl: false,
        tr: false,
        mt: false,
        mb: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'wall-h'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'wall-v') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: wallBrickV,
          repeat: 'repeat'
        }),
        lockScalingX: true,
        stroke: '#414142',
        strokeWidth: 2,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        tl: false,
        tr: false,
        ml: false,
        mr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'wall-v'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'door-h') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: doorH,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'door-h'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'door-v') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: doorV,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'door-v'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'booth') {
      try {
        const splitSize = props.size.split('x')
        props.width = parseInt(splitSize[0]) * 6
        props.height = parseInt(splitSize[1]) * 6
      } catch (_) {
        props.size = '6x6'
        props.width = 36
        props.height = 36
      }

      let source = booth6x6
      switch (props.size) {
        case '10x10':
          source = booth10x10
          break

        case '10x7':
          source = booth10x7
          break

        case '6x10':
          source = booth6x10
          break

        case '6x6':
          source = booth6x6
          break

        case '6x8':
          source = booth6x8
          break

        case '7x10':
          source = booth7x10
          break

        case '8x10':
          source = booth8x10
          break

        case '8x12':
          source = booth8x12
          break

        case '8x8':
          source = booth8x8
          break

        case '9x9':
          source = booth9x9
          break
      }

      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        fill: new fabric.Pattern({
          source,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false
      })

      const findBoothIndex = vm.SOLD_BOOTHS.findIndex(element => element === props.boothId)
      const boothColor = new fabric.Rect({
        width: props.width - 9,
        height: props.height,
        fill: findBoothIndex === -1 ? '#5ca270' : '#a77dc5',
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false
      })
      boothColor['mp:type'] = 'color'

      const boothId = new fabric.Text(typeof props.boothNumber === 'number' ? props.boothNumber.toString() : props.boothNumber, {
        angle: -props.rotate,
        fontSize: 16,
        fontWeight: 'bold',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fill: '#FFFFFF',
        originX: 'center',
        originY: 'center',
        left: 0.5 * (props.width - 9),
        top: 0.5 * props.height
      })
      boothId['mp:type'] = 'text'

      const group = new fabric.Group([rectangle, boothColor, boothId])
      group.snapAngle = 45
      group.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false,
        mtr: false
      })
      group['mp:element_id'] = props.id
      group['mp:id'] = props.name
      group['mp:type'] = 'booth'
      group['mp:size'] = props.size
      group['mp:_booth_id'] = props.boothId
      group['mp:_booth_number'] = props.boothNumber

      group.set({
        width: props.width,
        height: props.height,
        left: props.left,
        top: props.top,
        angle: props.rotate
      })

      // Lock Object
      group.set({
        hoverCursor: 'pointer',
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(group)

      elementIds.push(group['mp:id'])
      canvasRefs.push(group)
    } else if (type === 'stage') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: stage,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'stage'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'couch') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: couch,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'couch'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'flowerpot') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: flowerpot,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'flowerpot'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'pillar') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: pillar,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'pillar'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'front-desk') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: frontDesk,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'front-desk'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'table') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: table,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'table'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'small-table') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: smallTable,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'small-table'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'chair') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: chair,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'chair'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'toilet') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: toilet,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'toilet'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'sink') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        scaleX: props.scaleX,
        scaleY: props.scaleY,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: sink,
          repeat: 'repeat'
        }),
        lockScalingY: true,
        lockScalingX: true,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'sink'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'text') {
      const rectangle = new fabric.Text(props.text, {
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fontSize: props.size,
        fontWeight: 'bold',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fill: props.color,
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        mb: false,
        mt: false,
        ml: false,
        mr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'text'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'stone-floor') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: stoneFloor,
          repeat: 'repeat'
        }),
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'stone-floor'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'brick-floor') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: brickFloor,
          repeat: 'repeat'
        }),
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'brick-floor'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    } else if (type === 'grass-floor') {
      const rectangle = new fabric.Rect({
        width: props.width,
        height: props.height,
        left: props.left,
        top: props.top,
        angle: props.rotate,
        fill: new fabric.Pattern({
          source: grassFloor,
          repeat: 'repeat'
        }),
        objectCaching: false,
        selectable: false
      })
      rectangle.snapAngle = 45
      rectangle.setControlsVisibility({
        bl: false,
        br: false,
        tl: false,
        tr: false
      })
      rectangle['mp:id'] = props.name
      rectangle['mp:type'] = 'grass-floor'

      // Lock Object
      rectangle.set({
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        hasBorders: false
      })

      canvas.add(rectangle)

      elementIds.push(rectangle['mp:id'])
      canvasRefs.push(rectangle)
    }
  }

  const initialize = (data) => {
    if (isInitialized) {
      return
    }

    isInitialized = true

    // Construction
    stoneFloor = $document[0].getElementById('stone-floor')
    brickFloor = $document[0].getElementById('brick-floor')
    grassFloor = $document[0].getElementById('grass-floor')
    wallBrickH = $document[0].getElementById('wall-brick-h')
    wallBrickV = $document[0].getElementById('wall-brick-v')
    doorH = $document[0].getElementById('door-h')
    doorV = $document[0].getElementById('door-v')

    // Accessories
    stage = $document[0].getElementById('stage')
    couch = $document[0].getElementById('couch')
    flowerpot = $document[0].getElementById('flowerpot')
    pillar = $document[0].getElementById('pillar')
    frontDesk = $document[0].getElementById('front-desk')
    table = $document[0].getElementById('table')
    smallTable = $document[0].getElementById('small-table')
    chair = $document[0].getElementById('chair')
    toilet = $document[0].getElementById('toilet')
    sink = $document[0].getElementById('sink')

    // Booths
    booth10x10 = $document[0].getElementById('booth-10x10')
    booth10x7 = $document[0].getElementById('booth-10x7')
    booth6x10 = $document[0].getElementById('booth-6x10')
    booth6x6 = $document[0].getElementById('booth-6x6')
    booth6x8 = $document[0].getElementById('booth-6x8')
    booth7x10 = $document[0].getElementById('booth-7x10')
    booth8x10 = $document[0].getElementById('booth-8x10')
    booth8x12 = $document[0].getElementById('booth-8x12')
    booth8x8 = $document[0].getElementById('booth-8x8')
    booth9x9 = $document[0].getElementById('booth-9x9')

    canvas = new fabric.Canvas('map', {
      backgroundColor: data.map.backgroundColor
    })
    canvas.setWidth(window.screen.availWidth - 1)
    canvas.setHeight(vm.isMobile ? 400 : window.screen.availHeight)
    canvas.setViewportTransform(data.map.viewport.split(',').map(element => parseFloat(element)))
    canvas.set({
      skipOffscreen: true
    })

    canvas.on('mouse:down', function (opt) {
      const evt = opt.e
      if (evt.altKey === true || evt.ctrlKey === true) {
        this.isDragging = true
        this.selection = false
        this.lastPosX = evt.clientX
        this.lastPosY = evt.clientY
      }
    })

    if (!vm.isMobile) {
      canvas.on('mouse:move', function (opt) {
        if (this.isDragging) {
          const e = opt.e
          const vpt = this.viewportTransform
          vpt[4] += e.clientX - this.lastPosX
          vpt[5] += e.clientY - this.lastPosY
          this.requestRenderAll()
          this.lastPosX = e.clientX
          this.lastPosY = e.clientY
        }
      })

      canvas.on('mouse:up', function (opt) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        this.setViewportTransform(this.viewportTransform)
        this.isDragging = false
        this.selection = true
      })

      canvas.on('mouse:wheel', function (opt) {
        const evt = opt.e

        if (evt.shiftKey) {
          const delta = opt.e.deltaY
          let zoom = canvas.getZoom()
          zoom *= 0.999 ** delta
          if (zoom > 5) zoom = 5
          if (zoom < 0.01) zoom = 0.01
          canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom)
          opt.e.preventDefault()
          opt.e.stopPropagation()
        }
      })
    }

    let isDragging = false
    let pausePanning = false
    if (vm.isMobile) {
      let lastScale
      let point
      canvas.on('touch:gesture', function (e) {
        if (e.e.touches && e.e.touches.length === 2) {
          pausePanning = true
          if (e.self.state === 'start') {
            this.selection = false
            lastScale = e.self.scale

            point = new fabric.Point(e.self.x, e.self.y)
            return
          }
          if (e.self.state === 'end' || e.self.state === 'up') {
            this.selection = true

            return
          }

          let zoom = canvas.getZoom()
          if (lastScale < e.self.scale) {
            zoom *= 0.999 ** -24
          } else {
            zoom *= 0.999 ** 24
          }

          lastScale = e.self.scale

          if (zoom > 5) zoom = 5
          if (zoom < 0.01) zoom = 0.01
          canvas.zoomToPoint(point, zoom)
          e.e.preventDefault()
          e.e.stopPropagation()
        }
      })

      let lastPosX = 0
      let lastPosY = 0
      canvas.on('touch:drag', function (e) {
        if (e.self.state === 'up') {
          canvas.setViewportTransform(this.viewportTransform)
          isDragging = false
          pausePanning = false
          this.selection = true
        }

        if (pausePanning) {
          return
        }

        if (e.self.state === 'down') {
          isDragging = true
          this.selection = false
          lastPosX = e.self.x
          lastPosY = e.self.y
          return
        }

        if (e.self.state === 'move') {
          const currentX = e.self.x
          const currentY = e.self.y

          if (isDragging && (Math.abs(currentX - lastPosX) <= 50) && (Math.abs(currentY - lastPosY) <= 50)) {
            const vpt = this.viewportTransform
            vpt[4] += currentX - lastPosX
            vpt[5] += currentY - lastPosY
            this.requestRenderAll()
            lastPosX = currentX
            lastPosY = currentY
          }
        }
      })
    }

    const onSelect = (e) => {
      if (vm.isMobile && isDragging) {
        return
      }

      if (e.selected[0]['mp:type'] !== 'booth') {
        return
      }

      const findBoothIndex = vm.SOLD_BOOTHS.findIndex(element => element === e.selected[0]['mp:_booth_id'])
      if (findBoothIndex !== -1) {
        const findElement = vm.ELEMENTS.find(element => element.id === vm.SOLD_BOOTHS[findBoothIndex])
        if (findElement) {
          const findExhibitor = vm.ALL_EXHIBITORS.find(element => parseInt(element.booth, 10) === findElement.boothNumber)
          if (findExhibitor) {
            vm.toggleExhibitorModal(findExhibitor.id)
            $scope.$apply()
            return
          }
        }

        $rootScope.toggleGenericModal('Add to cart', 'This booth is already purchased.', () => {
          canvas.discardActiveObject()
        })

        $scope.$apply()
        return
      }

      vm.addToCartBtnLocked = true

      axios
        .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/BoothLockStatus.php?boothid=${e.selected[0]['mp:element_id']}` : `${process.env.LOCAL_APP_URL}/jSON/BoothLockStatus.php?boothid=${e.selected[0]['mp:element_id']}`)
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data.response.toLowerCase() === 'unlocked') {
              vm.boothLocked = false
              vm.addToCartBtnLocked = false
            } else {
              vm.boothLocked = true
            }

            $scope.$apply()
          }
        })
        .catch(e => console.error(e))

      vm.selectedBoothId = e.selected[0]['mp:element_id']
      vm.purchaseModalVisible = true

      $scope.$apply()
    }

    canvas.on('selection:created', onSelect)
    canvas.on('selection:updated', onSelect)
    canvas.on('selection:cleared', () => {
      vm.selectedBoothId = 0
    })

    data.elements.sort((a, b) => a.zIndex - b.zIndex)
    for (const element of data.elements) {
      fillFromDB(element.type, element)
    }
  }

  vm.togglePurchaseModal = function () {
    vm.EXPO_SLOTS.value = null
    canvas.discardActiveObject()

    if (vm.purchaseModalVisible) {
      vm.purchaseModalVisible = false
    } else {
      vm.purchaseModalVisible = true
    }
  }

  vm.getBooth = function () {
    const findIndex = vm.ELEMENTS.findIndex(element => element.id === vm.selectedBoothId)
    if (findIndex === -1) {
      return {
        id: 0,
        name: 'Unknown',
        boothId: 0,
        boothNumber: '0',
        type: 'booth',
        width: 0,
        height: 0,
        size: '0x0',
        rotate: 0,
        color: '#000000',
        left: 0,
        top: 0,
        zIndex: 0,
        text: 'Unknown',
        picture: '',
        price: 0.00
      }
    }

    return vm.ELEMENTS[findIndex]
  }

  vm.openUrl = function (url) {
    window.open(url, '_blank')
  }

  const applyFilters = () => {
    const exhibitors = [...vm.ALL_EXHIBITORS]

    if (vm.query === '') {
      vm.EXHIBITORS = exhibitors
      return
    }

    vm.EXHIBITORS = exhibitors.filter(element => element.title.toLowerCase().includes(vm.query.toLowerCase()) || element.link.toLowerCase().includes(vm.query.toLowerCase()) || element.booth.toLowerCase().includes(vm.query.toLowerCase()))
  }

  vm.changeExpoSlot = function (value) {
    vm.EXPO_SLOTS.value = value
  }

  vm.buyBooth = function () {
    const CART_ITEMS = $rootScope.getCartItems()

    const hasBooth = CART_ITEMS.findIndex(element => element.itemType === 'ticket') !== -1
    if (hasBooth) {
      vm.togglePurchaseModal()
      $rootScope.toggleGenericModal('Add to cart', 'It seems that you already have tickets in your cart. Please empty your cart and try again.')
      return
    }

    vm.lockingBooth = true

    axios
      .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/BoothLock.php?boothid=${vm.selectedBoothId}` : `${process.env.LOCAL_APP_URL}/jSON/BoothLock.php?boothid=${vm.selectedBoothId}`)
      .then(response => {
        if (response.status === 200) {
          const data = response.data

          if (data.response.toLowerCase() === 'locked') {
            vm.boothLocked = true
            $rootScope.addItemToCart({
              id: vm.selectedBoothId,
              slotId: vm.EXPO_SLOTS.value ? vm.EXPO_SLOTS.value.value : null,
              type: 'booth',
              name: `Booth ${vm.getBooth().boothNumber} - ${vm.INFO.name}`,
              quantity: 1,
              price: vm.TOTAL,
              picture: vm.getBooth().picture,
              extras: vm.ORDER
            })
            vm.togglePurchaseModal()
          } else {
            vm.boothLocked = false
            $rootScope.toggleGenericModal('Purchase Booth', 'This booth is locked by someone else. Please try again in 15 minutes.')

            $scope.$apply()
          }
        }
      })
      .catch(e => console.error(e))
      .finally(() => {
        vm.lockingBooth = false
        $scope.$apply()
      })
  }

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/EventScreen.php?eid=${$routeParams.id}` : `${process.env.LOCAL_APP_URL}/jSON/EventScreen.php?eid=${$routeParams.id}`)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data

        vm.PROGRAM_GUIDE_URL = data.programguide

        $scope.$apply()
      }
    })
    .catch(e => alert(e))

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/FloorPlan.php?eid=${$routeParams.id}` : `${process.env.LOCAL_APP_URL}/jSON/FloorPlan.php?eid=${$routeParams.id}`)
    .then(response => {
      if (response.status === 200) {
        const data = response.data

        vm.INFO = data.info
        vm.ELEMENTS = data.elements
        vm.ALL_EXHIBITORS = data.exhibitors
        vm.EXHIBITORS = data.exhibitors
        vm.FORMS = data.forms
        vm.EXTRAS = data.extras
        vm.SOLD_BOOTHS = data.booths

        vm.INFO.formattedDate = `${moment.utc(data.info.startDate).format('MMMM Do')} - ${moment.utc(data.info.endDate).format('MMMM Do')}, ${moment.utc(data.info.startDate).format('YYYY')}`

        LOADING_PROGRESS.mapData = data

        const allImages = document.querySelectorAll('img')

        const promises = []
        for (const image of allImages) {
          promises.push(new Promise((resolve, reject) => {
            if (image.complete) {
              resolve()
              return
            }

            image.addEventListener('load', resolve)
            image.addEventListener('error', reject)
          }))
        }
        Promise.all(promises).finally(() => {
          LOADING_PROGRESS.images = true

          $scope.$apply()
        })
      }
    })
    .catch(e => console.error(e))

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/ExpoSlots.php?eid=${$routeParams.id}` : `${process.env.LOCAL_APP_URL}/jSON/ExpoSlots.php?eid=${$routeParams.id}`)
    .then(response => {
      if (response.status === 200) {
        const data = response.data

        vm.EXPO_SLOTS.options = data.expoSlots.map(element => {
          if ('lectureDiscount' in element) {
            element.lectureDiscount = parseFloat(element.lectureDiscount)
          }

          const slotDate = moment.utc(element.slotDate, 'MMM DD, YYYY')
          const label = `${element.roomNumber} - ${slotDate.format('dddd, MMMM D, YYYY')} - ${element.startTime} - ${element.endTime}`

          return {
            value: element.id,
            label,
            lectureDiscount: element.lectureDiscount
          }
        })

        $scope.$apply()
      }
    })
    .catch(e => console.error(e))

  $scope.$watch(function () {
    return $rootScope.cartType
  }, function (newValue) {
    vm.cartType = newValue
  })

  $scope.$watch(function () {
    return vm.query
  }, function (newValue) {
    if (newValue) {
      applyFilters()
    }
  })

  $scope.$watch(() => vm.purchaseModalVisible, (newValue) => {
    if (newValue) {
      vm.ORDER = vm.EXTRAS.map(element => ({
        id: element.id,
        name: element.name,
        price: element.price,
        quantity: 0
      }))
    }
  })

  $scope.$watch(() => vm.ORDER, (newValue) => {
    const price = vm.getBooth().price

    const sumOfItems = vm.ORDER.reduce((previous, current) => previous + (current.price * current.quantity), 0)

    vm.TOTAL = sumOfItems + price
    if (!vm.SKIP_LECTURE) {
      if (vm.EXPO_SLOTS.value && vm.EXPO_SLOTS.value.lectureDiscount !== null && vm.EXPO_SLOTS.value.lectureDiscount !== undefined) {
        vm.TOTAL -= vm.EXPO_SLOTS.value.lectureDiscount
      }
    }
  }, true)

  $scope.$watch(() => vm.EXPO_SLOTS.value, (newValue, oldValue) => {
    if (newValue === oldValue) {
      return
    }

    const price = vm.getBooth().price

    const sumOfItems = vm.ORDER.reduce((previous, current) => previous + (current.price * current.quantity), 0)

    vm.TOTAL = sumOfItems + price
    if (!vm.SKIP_LECTURE) {
      if (vm.EXPO_SLOTS.value && vm.EXPO_SLOTS.value.lectureDiscount !== null && vm.EXPO_SLOTS.value.lectureDiscount !== undefined) {
        vm.TOTAL -= vm.EXPO_SLOTS.value.lectureDiscount
      }
    }
  })

  $scope.$watch(() => vm.SKIP_LECTURE, (newValue, oldValue) => {
    if (newValue === oldValue) {
      return
    }

    const price = vm.getBooth().price

    const sumOfItems = vm.ORDER.reduce((previous, current) => previous + (current.price * current.quantity), 0)

    vm.TOTAL = sumOfItems + price
    if (!vm.SKIP_LECTURE) {
      if (vm.EXPO_SLOTS.value && vm.EXPO_SLOTS.value.lectureDiscount !== null && vm.EXPO_SLOTS.value.lectureDiscount !== undefined) {
        vm.TOTAL -= vm.EXPO_SLOTS.value.lectureDiscount
      }
    }
  })

  $scope.$watch(() => vm.selectedBoothId, (newValue, oldValue) => {
    if (newValue === oldValue) {
      return
    }

    const price = vm.getBooth().price

    const sumOfItems = vm.ORDER.reduce((previous, current) => previous + (current.price * current.quantity), 0)

    vm.TOTAL = sumOfItems + price
    if (!vm.SKIP_LECTURE) {
      if (vm.EXPO_SLOTS.value && vm.EXPO_SLOTS.value.lectureDiscount !== null && vm.EXPO_SLOTS.value.lectureDiscount !== undefined) {
        vm.TOTAL -= vm.EXPO_SLOTS.value.lectureDiscount
      }
    }
  })

  $scope.$watch(function () {
    return LOADING_PROGRESS
  }, (newValue, oldValue) => {
    if (newValue.images && newValue.mapData !== null) {
      initialize(newValue.mapData)
    }
  }, true)
}

angular.module('app').controller('FloorPlanController', ['$scope', '$rootScope', '$document', '$routeParams', FloorPlanController])
