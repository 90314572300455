import angular from 'angular'
import axios from 'axios'

function AttendeeController ($scope) {
  const vm = this

  vm.CONTENT = {
    header: {
      image: `${window.PUBLIC_PATH}images/il-attendee-header.webp`,
      imageTitle: 'Become a sponsor'
    },
    container1: {
      title: '',
      content: ''
    },
    container2: {
      image: `${window.PUBLIC_PATH}images/il-attendee-footer.webp`,
      imageTitle: 'Become a sponsor'
    },
    list: []
  }

  axios.get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/SponsorScreen.php` : `${process.env.LOCAL_APP_URL}/jSON/SponsorScreen.php`).then(response => {
    if (response.status === 200) {
      const data = response.data

      vm.CONTENT.header = data.header
      vm.CONTENT.container1 = data.container1
      vm.CONTENT.container2 = data.container2
      vm.CONTENT.list = data.list

      $scope.$apply()
    }
  })
}

angular.module('app').controller('AttendeeController', ['$scope', AttendeeController])
