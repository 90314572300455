import angular from 'angular'
import axios from 'axios'

function CheckoutController ($scope, $rootScope, $timeout, $location) {
  const vm = this

  vm.isLoading = false

  vm.PAYMENT_METHOD = 1

  vm.getCartItems = () => []
  vm.increment = (_) => { }
  vm.decrement = (_) => { }
  vm.remove = (_) => { }

  vm.ITEMS_TOTAL = 0.00
  vm.SERVICE_FEE = 0.00
  vm.CREDITS = 0.00
  vm.CREDITS_APPLIED = 0.00
  vm.TOTAL = 0.00
  vm.showCredits = false
  vm.hasSlot = false

  vm.changePaymentMethod = (index) => {
    vm.PAYMENT_METHOD = index
  }

  const proceedPayment = (type) => {
    const formData = new FormData()
    formData.append('cartID', localStorage.getItem('cartId'))
    formData.append('orderTotal', vm.TOTAL)

    let options = {}
    if (type === 'booth') {
      options = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      }
    }

    axios
      .post(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/event/${type === 'booth' ? 'boothppurl' : 'ticketppurl'}.php` : `${process.env.LOCAL_APP_URL}/event/${type === 'booth' ? 'boothppurl' : 'ticketppurl'}.php`, formData, options)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data

          if (data.status === 200 && data.order_id) {
            localStorage.removeItem('cart')
            localStorage.removeItem('cartId')

            $location.path('/payment-success/' + data.order_id + '/booth')
            return
          }

          if (data.status === 1) {
            window.location = data.paypalurl
          }
        }
      })
      .catch(() => {
        $rootScope.toggleGenericModal('Checkout', 'We could not process your request. Please try again.')
      })
      .finally(() => {
        vm.isLoading = false
        $scope.$apply()
      })
  }

  vm.hasAnyExtras = (item) => {
    if (item.itemType !== 'booth') {
      return false
    }

    return item.extras.some(element => element.quantity > 0)
  }

  vm.checkout = () => {
    const items = JSON.parse(JSON.stringify([...vm.getCartItems()]))
    if (items.length === 0) {
      return
    }

    const type = items[0].itemType === 'booth' ? 'booth' : 'ticket'

    if (type === 'booth' && localStorage.getItem('accessToken') === null) {
      $rootScope.toggleGenericModal('Checkout', 'You\'re not logged in.', () => {
        $timeout(() => {
          $rootScope.toggleSignInModal()
          $scope.$apply()
        }, 100)
      }, 'Sign In')
      return
    }

    vm.isLoading = true

    const cartId = localStorage.getItem('cartId')
    if (cartId !== null) {
      proceedPayment(type)
      return
    }

    const payload = items.map(element => {
      // if (element.type) {
      //   element.itemType = element.type
      //   delete element.type
      // }

      if (element.extras) {
        element.extras = element.extras.filter(extra => extra.quantity > 0).map(extra => {
          extra.itemId = extra.id
          extra.itemType = 'extra'
          delete extra.id
          delete extra.price

          return extra
        })
      }

      return element
    })

    axios
      .post(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/${type === 'booth' ? 'BoothCart' : 'TicketCart'}.php` : `${process.env.LOCAL_APP_URL}/jSON/${type === 'booth' ? 'BoothCart' : 'TicketCart'}.php`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data

          if (data.status === 1) {
            localStorage.setItem('cartId', data.cartID)

            proceedPayment(type)
          }
        }
      })
      .catch(() => {
        vm.isLoading = false
        $rootScope.toggleGenericModal('Checkout', 'We could not process your request. Please try again.')
      })
  }

  $rootScope.$watch(() => $rootScope.getCartItems, (newValue) => {
    if (newValue) {
      window.scrollTo(0, 0)
      vm.getCartItems = $rootScope.getCartItems
      vm.increment = $rootScope.increment
      vm.decrement = $rootScope.decrement
      vm.remove = $rootScope.remove
    }
  })

  $scope.$watch(() => vm.getCartItems(), (newValue) => {
    vm.showCredits = newValue.findIndex(item => item.itemType !== 'booth') === -1
    vm.hasSlot = newValue.findIndex(item => item.slotId !== null) !== -1

    vm.ITEMS_TOTAL = newValue.reduce((previous, current) => previous + (current.quantity * current.price), 0)
    vm.TOTAL = 0
    if (vm.showCredits) {
      if (vm.CREDITS > (vm.ITEMS_TOTAL + vm.SERVICE_FEE)) {
        vm.CREDITS_APPLIED = vm.ITEMS_TOTAL + vm.SERVICE_FEE
      } else {
        vm.CREDITS_APPLIED = vm.CREDITS
      }

      vm.TOTAL = vm.ITEMS_TOTAL + vm.SERVICE_FEE - vm.CREDITS
    } else {
      vm.CREDITS_APPLIED = 0.00
      vm.TOTAL = vm.ITEMS_TOTAL + vm.SERVICE_FEE
    }
    if (vm.TOTAL < 0) {
      vm.TOTAL = 0
    }
  }, true)

  $scope.$watch(() => $rootScope.SIGNED_IN, (newValue) => {
    if (newValue) {
      const user = localStorage.getItem('user')
      if (user !== null) {
        const parsedUser = JSON.parse(user)

        vm.CREDITS = parsedUser.credits
      }
    } else {
      vm.CREDITS = 0
    }

    const items = vm.getCartItems()
    if (items) {
      vm.showCredits = items.findIndex(item => item.itemType !== 'booth') === -1
      vm.hasSlot = items.findIndex(item => item.slotId !== null) !== -1

      vm.ITEMS_TOTAL = items.reduce((previous, current) => previous + (current.quantity * current.price), 0)
      vm.TOTAL = 0
      if (vm.showCredits) {
        if (vm.CREDITS > (vm.ITEMS_TOTAL + vm.SERVICE_FEE)) {
          vm.CREDITS_APPLIED = (vm.ITEMS_TOTAL + vm.SERVICE_FEE)
        } else {
          vm.CREDITS_APPLIED = vm.CREDITS
        }

        vm.TOTAL = vm.ITEMS_TOTAL + vm.SERVICE_FEE - vm.CREDITS
      } else {
        vm.CREDITS_APPLIED = 0.00
        vm.TOTAL = vm.ITEMS_TOTAL + vm.SERVICE_FEE
      }
      if (vm.TOTAL < 0) {
        vm.TOTAL = 0
      }
    }
  }, true)

  const user = localStorage.getItem('user')
  if (user !== null) {
    const parsedUser = JSON.parse(user)

    vm.CREDITS = parsedUser.credits
  }
}

angular.module('app').controller('CheckoutController', ['$scope', '$rootScope', '$timeout', '$location', CheckoutController])
