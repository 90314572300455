import angular from 'angular'
import axios from 'axios'

function PerformerJoinController ($scope, $rootScope, vcRecaptchaService) {
  const vm = this

  const ERROR_MESSAGES = {
    name: 'Please enter your name.',
    companyName: 'Please enter your company name.',
    email: 'Please enter a valid email address.',
    phoneNumber: 'Please enter a valid phone number.',
    password: 'Please enter a password.',
    passwordConfirmation: 'Please enter a password confirmation.',
    performanceTitle: 'Please enter your performance title.',
    shortDescription: 'Please enter your short description.',
    address: 'Please enter your address.',
    city: 'Please enter your city.',
    postalCode: 'Please enter your postal code.'
  }

  vm.TERMS = ''
  vm.RULES = false
  vm.isLoading = false

  vm.widgetId = null
  vm.reCaptcha = null

  vm.CITIES = {
    value: null,
    options: []
  }

  vm.STATES = {
    value: null,
    options: []
  }

  vm.COUNTRIES = {
    value: null,
    options: []
  }

  vm.FORM = {
    name: '',
    companyName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    performanceTitle: '',
    shortDescription: '',
    address: '',
    phoneNumber: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    citiesOfInterest: []
  }

  vm.FORM_ERRORS = {
    name: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    password: '',
    passwordConfirmation: '',
    performanceTitle: '',
    shortDescription: '',
    address: '',
    city: '',
    postalCode: ''
  }

  vm.DROPDOWN_ERRORS = {
    state: '',
    country: '',
    citiesOfInterest: ''
  }

  vm.setWidgetId = function (widgetId) {
    vm.widgetId = widgetId
  }

  vm.hasError = function (name) {
    return vm.FORM_ERRORS[name] !== ''
  }

  vm.hasDropdownError = function (name) {
    return vm.DROPDOWN_ERRORS[name] !== ''
  }

  vm.hasAnyErrors = function () {
    let isValid = true
    for (const value of Object.values(vm.FORM_ERRORS)) {
      if (value !== '') {
        isValid = false
        break
      }
    }

    return isValid
  }

  vm.allFieldsFilled = function () {
    let isValid = true
    for (const key of Object.keys(vm.FORM_ERRORS)) {
      if (vm.FORM[key] === '') {
        isValid = false
        break
      }
    }

    return isValid
  }

  vm.changeCountry = function (value) {
    vm.COUNTRIES.value = value
  }

  vm.changeState = function (value) {
    vm.STATES.value = value
  }

  vm.send = () => {
    if (vm.STATES.value === null) {
      vm.DROPDOWN_ERRORS.state = 'Please select a state.'
    } else {
      vm.DROPDOWN_ERRORS.state = ''
    }
    if (vm.COUNTRIES.value === null) {
      vm.DROPDOWN_ERRORS.country = 'Please select a country.'
    } else {
      vm.DROPDOWN_ERRORS.country = ''
    }
    if (vm.FORM.citiesOfInterest.length === 0) {
      vm.DROPDOWN_ERRORS.citiesOfInterest = 'Please select at least one city of interest.'
    } else {
      vm.DROPDOWN_ERRORS.citiesOfInterest = ''
    }

    let isValid = true
    for (const value of Object.values(vm.DROPDOWN_ERRORS)) {
      if (value !== '') {
        isValid = false
        break
      }
    }
    if (!isValid) {
      return
    }

    if (vcRecaptchaService.getResponse(vm.widgetId) === '') {
      $rootScope.toggleGenericModal('Become a performer', 'Please check the reCaptcha box.')
      return
    }

    vm.FORM.state = vm.STATES.value.value
    vm.FORM.country = vm.COUNTRIES.value.value

    const formData = new FormData()
    formData.append('name', vm.FORM.name)
    formData.append('companyname', vm.FORM.companyname)
    formData.append('email', vm.FORM.email)
    formData.append('password', vm.FORM.password)
    formData.append('phone', vm.FORM.phoneNumber)
    formData.append('address', vm.FORM.address)
    formData.append('city', vm.FORM.city)
    formData.append('state', vm.FORM.state)
    formData.append('country', vm.FORM.country)
    formData.append('zip', vm.FORM.postalCode)
    formData.append('title', vm.FORM.title)
    formData.append('citiesofinterest', vm.FORM.citiesOfInterest.join(','))
    formData.append('short_description', vm.FORM.shortDescription)

    axios
      .post(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/SpeakerForm.php` : `${process.env.LOCAL_APP_URL}/jSON/SpeakerForm.php`, formData)
      .then(() => {
        $rootScope.toggleGenericModal('Become a performer', 'Thank you for submitting your interest in performing/presenting! If we are interested in moving forwad, we will be in touch. Please note that Vendors receive prime presentation times, check out the floorplan for available booths!', () => {
          $rootScope.navigateTo('/performer')
        })
      })
      .catch(e => {
        console.error(e)
        if (e.response && e.response.data && e.response.data.responsemsg) {
          $rootScope.toggleGenericModal('Become a performer', e.response.data.responsemsg)
        } else {
          $rootScope.toggleGenericModal('Become a performer', 'We are sorry, but something went wrong. Please try again later.')
        }
      })
      .finally(() => {
        vm.isLoading = false
        $scope.$apply()
      })
  }

  const validateDropdowns = function (type, newValue, oldValue) {
    // Skip initial page load check.
    if (newValue === oldValue) {
      return
    }

    if (type === 'state' || type === 'country') {
      if (newValue === null) {
        vm.DROPDOWN_ERRORS[type] = 'Please select a state.'
      } else {
        vm.DROPDOWN_ERRORS[type] = ''
      }
      if (newValue === null) {
        vm.DROPDOWN_ERRORS[type] = 'Please select a country.'
      } else {
        vm.DROPDOWN_ERRORS[type] = ''
      }
    }
  }

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/PerformerFormTerms.php` : `${process.env.LOCAL_APP_URL}/jSON/PerformerFormTerms.php`)
    .then(response => {
      if (response.status === 200) {
        const data = response.data

        vm.TERMS = data.terms
      }
    })
    .catch(e => console.error(e))

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/StatesCountries.php` : `${process.env.LOCAL_APP_URL}/jSON/StatesCountries.php`)
    .then(response => {
      if (response.status === 200) {
        const data = response.data

        vm.COUNTRIES.options = data.countries.map(element => ({
          value: element.id,
          label: element.name
        }))

        vm.STATES.options = data.states.map(element => ({
          value: element.id,
          label: element.name
        }))
      }
    })
    .catch(e => console.error(e))

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/getZonesCities.php` : `${process.env.LOCAL_APP_URL}/jSON/getZonesCities.php`)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data

        vm.CITIES.options = data.allCities.map(element => ({
          label: element.name,
          value: element.citiId
        }))
      }
    })
    .catch(e => alert(e))

  $scope.$watch(function () {
    return vm.FORM
  }, function (newValue) {
    for (const key of Object.keys(vm.FORM_ERRORS)) {
      if (newValue[key] === undefined) {
        vm.FORM_ERRORS[key] = ERROR_MESSAGES[key]
      } else {
        vm.FORM_ERRORS[key] = ''
      }
    }

    if (vm.FORM_ERRORS.passwordConfirmation === '') {
      if (newValue.password !== '' && newValue.passwordConfirmation !== '' && newValue.password !== newValue.passwordConfirmation) {
        vm.FORM_ERRORS.passwordConfirmation = 'Passwords does not match.'
      } else {
        vm.FORM_ERRORS.passwordConfirmation = ''
      }
    }
  }, true)

  $scope.$watch(function () {
    return vm.STATES.value
  }, function (newValue, oldValue) {
    validateDropdowns('state', newValue, oldValue)
  }, true)

  $scope.$watch(function () {
    return vm.COUNTRIES.value
  }, function (newValue, oldValue) {
    validateDropdowns('country', newValue, oldValue)
  }, true)
}

angular.module('app').controller('PerformerJoinController', ['$scope', '$rootScope', 'vcRecaptchaService', PerformerJoinController])
