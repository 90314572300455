import angular from 'angular'
import axios from 'axios'

function CareersController ($scope) {
  const vm = this

  vm.CONTENT = {
    header: {
      headerImage: `${window.PUBLIC_PATH}images/il-careers-header.webp`,
      headerImageTitle: 'Body mind spirit',
      headerImageText: 'Careers'
    },
    container1: {
      title: '',
      content: '',
      container1Image: `${window.PUBLIC_PATH}images/il-careers-header-separator.webp`,
      container1ImageText: 'Career openings'
    },
    container2: {
      title: '',
      list: [],
      content: ''
    }
  }

  axios
    .get(process.env.NODE_ENV === 'production' ? `${process.env.APP_URL}/jSON/CareersScreen.php` : `${process.env.LOCAL_APP_URL}/jSON/CareersScreen.php`)
    .then(response => {
      if (response.status === 200) {
        const data = response.data

        vm.CONTENT.header = data.header
        vm.CONTENT.container1 = data.container1
        vm.CONTENT.container2 = data.container2

        $scope.$apply()
      }
    })
    .catch(e => alert(e))
}

angular.module('app').controller('CareersController', ['$scope', CareersController])
